import './style.css';
import {Link} from 'react-router-dom';

function Header(){
    return(
        <footer>
            <h1><a target='_blank' rel='noreferrer' href='http://www.sunsalesystem.com.br/' >SunSale System</a></h1>
        </footer>
    )
}

export default Header;